<template>
  <div class="text-left">
    <BaseHeader :title="`Client: ${client.first_name || ''}`"></BaseHeader>

    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <section v-else>
      <div class="card has-shadow mt-4">
        <div class="card-body">
          <v-row>
            <v-col cols="12" sm="6">
              <div class="media">
                <div class="media-body">
                  <h3 class="mt-0">
                    {{ client.first_name || "" }} {{ client.last_name || "" }}
                  </h3>
                  <p class="my-1">
                    <i class="icon-envelope mr-2 text-primary"></i
                    >{{ client.email || "" }}
                  </p>
                  <p class="my-1">
                    <i class="icon-phone mr-2 text-primary"></i>
                    {{ client.phone || "" }}
                  </p>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <h4 class="mt-0">
                Ftp Credentials

                <v-btn
                  color="primary"
                  class="float-right ml-2"
                  small
                  v-if="!connections || connections.length <= 0"
                  @click.stop="ftp_dialog = !ftp_dialog"
                  >Configure</v-btn
                >
                <v-btn
                  color="primary"
                  class="float-right ml-2"
                  small
                  @click.stop="crudAction"
                >
                  Update FTP
                </v-btn>
              </h4>
              <!-- datatable -->
              <div
                class="table-responsive border-muted-1"
                v-if="connections && connections.length > 0"
              >
                <table class="table table-striped">
                  <tr
                    v-for="(conn, index) in connections"
                    :key="index + 'conn'"
                  >
                    <td>{{ conn.subject || "" }}</td>
                    <td :class="conn.value ? 'success--text' : 'error--text'">
                      {{ conn.value || "Not Set" }}
                    </td>
                  </tr>
                </table>
              </div>
              <!-- /datatable -->
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="card mt-2 border-0 bg-white shadow">
        <div class="card-body text-right d-flex" style>
          <h1>Webistes</h1>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="$store.state.search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="my-auto"
          ></v-text-field>

          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click.stop="$store.state.dialog = true"
          >
            New Site
          </v-btn>
        </div>
        <!-- datatable -->
        <v-data-table
          :mobile-breakpoint="0"
          :headers="headers"
          :items="sites || []"
          :search="$store.state.search"
        >
          <template v-slot:[`item.deleted`]="{ item }">
            <v-chip v-if="item.deleted" class="ma-2" color="error" small
              >Inactive</v-chip
            >
            <v-chip v-else class="ma-2" color="primary" text-color="white" small
              >Active</v-chip
            >
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn x-small fab elevation="0" @click="editMode2(item)">
              <v-icon color="primary">mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <!-- /datatable -->
      </div>

      <!-- clients modal -->
      <v-dialog
        v-model="$store.state.dialog"
        class="text-left"
        transition="dialog-top-transition"
        max-width="600"
      >
        <v-card :loading="sitesForm.busy">
          <v-card-title class="text-h5"
            >{{ isEditMode ? "Edit" : "New" }} Site</v-card-title
          >
          <v-card-text>
            <!--  form -->
            <form
              class="flex-fill"
              ref="sitesForm"
              @submit.prevent="createSite"
            >
              <div class>
                <div class="text-left mb-3">
                  <span class="d-block text-muted"
                    >All fields are required</span
                  >
                </div>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Site Name"
                      name="site_name"
                      type="site_name"
                      v-model="sitesForm.name"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="sitesForm"
                            class="v-messages theme--light error--text"
                            field="site_name"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Site Url"
                      name="url"
                      type="link"
                      v-model="sitesForm.url"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="sitesForm"
                            class="v-messages theme--light error--text"
                            field="url"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Gateway Checkout Url"
                      name="gateway_checkout_url"
                      type="link"
                      v-model="sitesForm.gateway_checkout_url"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="sitesForm"
                            class="v-messages theme--light error--text"
                            field="gateway_checkout_url"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Application Url"
                      name="application_url"
                      type="link"
                      v-model="sitesForm.application_url"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="sitesForm"
                            class="v-messages theme--light error--text"
                            field="application_url"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Site Email"
                      name="email"
                      type="email"
                      v-model="sitesForm.email"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="sitesForm"
                            class="v-messages theme--light error--text"
                            field="email"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Tawk Code"
                      name="tawk_code"
                      type="text"
                      v-model="sitesForm.tawk_code"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="sitesForm"
                            class="v-messages theme--light error--text"
                            field="tawk_code"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Captcha Site Key"
                      name="site_key"
                      type="text"
                      v-model="sitesForm.site_key"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="sitesForm"
                            class="v-messages theme--light error--text"
                            field="site_key"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Captcha Secret Key"
                      name="secret_key"
                      type="text"
                      v-model="sitesForm.secret_key"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="sitesForm"
                            class="v-messages theme--light error--text"
                            field="secret_key"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-switch
                      class="mt-0"
                      v-model="sitesForm.is_admin_system"
                      label="Is admin system"
                      dense
                      hide-details
                    >
                    </v-switch>
                  </v-col>

                  <v-col cols="12" md="6" v-if="!sitesForm.is_admin_system">
                    <v-select
                      dense
                      outlined
                      label="Select admin system"
                      :items="adminSystems"
                      item-text="application_url"
                      item-value="admin_system"
                      name="admin_system"
                      v-model="sitesForm.admin_system"
                      hide-details
                    ></v-select>
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="sitesForm"
                            class="v-messages theme--light error--text"
                            field="admin_system"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" class="text-left">
                    <v-btn
                      :disabled="sitesForm.busy"
                      type="submit"
                      color="primary"
                      >{{ isEditMode ? "Update" : "Create" }} Site</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </form>

            <!-- / form -->
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- clients modal -->

      <!-- clients modal -->
      <v-dialog
        v-model="ftp_dialog"
        class="text-left"
        transition="dialog-top-transition"
        max-width="600"
      >
        <v-card :loading="connectionsForm.busy">
          <v-card-title class="text-h5">
            {{ edit ? "Update connection" : "Configure FTP Credentials" }}
          </v-card-title>
          <v-card-text>
            <!--  form -->
            <form
              class="flex-fill text-left"
              ref="connectionsForm"
              @submit.prevent="createConnection"
            >
              <div class>
                <div class="mb-3">
                  <span class="d-block text-muted">Files Server Configs</span>
                </div>
                <v-row>
                  <v-col cols="12" md="4" class="pt-0">
                    <v-text-field
                      dense
                      outlined
                      label="FTP Server"
                      name="ftp_server"
                      type="text"
                      min="0"
                      v-model="connectionsForm.ftp_server"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="connectionsForm"
                            class="v-messages theme--light error--text"
                            field="ftp_server"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="4" class="pt-0">
                    <v-text-field
                      dense
                      outlined
                      label="FTP Port"
                      name="ftp_port"
                      type="text"
                      min="0"
                      v-model="connectionsForm.ftp_port"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="connectionsForm"
                            class="v-messages theme--light error--text"
                            field="ftp_port"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="4" class="pt-0">
                    <v-text-field
                      dense
                      outlined
                      label="FTP Dir"
                      name="ftp_dir"
                      type="text"
                      min="0"
                      v-model="connectionsForm.ftp_dir"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="connectionsForm"
                            class="v-messages theme--light error--text"
                            field="ftp_dir"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6" class="pt-0">
                    <v-text-field
                      dense
                      outlined
                      label="FTP user"
                      name="ftp_user"
                      type="text"
                      min="0"
                      v-model="connectionsForm.ftp_user"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="connectionsForm"
                            class="v-messages theme--light error--text"
                            field="ftp_user"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6" class="pt-0">
                    <v-text-field
                      dense
                      outlined
                      label="FTP password"
                      name="ftp_password"
                      type="password"
                      min="0"
                      v-model="connectionsForm.ftp_password"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="connectionsForm"
                            class="v-messages theme--light error--text"
                            field="ftp_password"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <v-btn-toggle>
                  <v-btn
                    :disabled="connectionsForm.busy"
                    type="submit"
                    color="primary"
                    class="white--text"
                    >{{ edit ? "Update" : "Save" }}</v-btn
                  >
                  <v-btn type="button" @click="ftp_dialog = !ftp_dialog"
                    >Close</v-btn
                  >
                </v-btn-toggle>
              </div>
            </form>

            <!-- / form -->
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- clients modal -->
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Sites",
  computed: {
    ...mapState("sites", [
      "sitesForm",
      "loading",
      "sites",
      "headers",
      "adminSystems",
    ]),
    ...mapState("clients", ["clients", "client"]),
    ...mapState("connections", ["connectionsForm"]),
  },
  data() {
    return {
      countries: [],
      ftp_dialog: false,
      edit: false,
      connections: {},
      isEditMode: false,
    };
  },

  methods: {
    ...mapActions("sites", ["getSites", "getGetAdminSystems"]),
    ...mapActions("clients", ["getClients", "getClient"]),
    editMode2(item) {
      console.log(item);
      console.log("this.sitesForm", this.sitesForm);
      this.activeItem = item;
      Object.assign(this.sitesForm, { ...item });
      this.sitesForm.is_admin_system =
        this.sitesForm.is_admin_system !== "0" ? true : false;
      this.sitesForm.site_id = item.id;
      this.isEditMode = true;
      this.$store.state.dialog = true;
    },
    getConnections() {
      this.$http
        .get(`/Setups/FTPConnections/${this.$route.params.id}`)
        .then((res) => {
          this.connections = res.data.Data.connections;
          console.log(res);
        })
        .catch((err) => console.log(err));
    },
    createSite() {
      if (this.sitesForm.is_admin_system) {
        this.sitesForm.is_admin_system = 1;
        this.sitesForm.admin_system = "0";
      } else {
        this.sitesForm.is_admin_system = false;
      }
      // save new sites as without admin system always
      // this.sitesForm.is_admin_system = "0";
      // this.sitesForm.client_id = '6'
      // this.sitesForm.admin_system = "160";
      this.isEditMode
        ? this.sitesForm
            .put(`${this.$baseUrl}/Sites/Site`)
            .then(() => {
              this.getSites();
              this.getGetAdminSystems();
              this.$store.state.dialog = false;
              this.$notify({
                title: "Success",
                text: "Site updated successifully.",
                style: "success",
              });
              this.isEditMode = false;
              this.sitesForm.reset();
            })
            .catch((err) => {
              console.log(err);
              this.$notify({
                title: "Error",
                text: err.response.data
                  ? err.response.data.Message
                  : "Error while updating site",
                style: "danger",
              });
            })
        : this.sitesForm
            .post(`${this.$baseUrl}/Sites/Site`)
            .then(() => {
              this.getSites();
              this.$store.state.dialog = false;
              this.$notify({
                title: "Success",
                text: "Site created successifully.",
                style: "success",
              });
              this.isEditMode = false;
              this.sitesForm.reset();
            })
            .catch((err) => {
              console.log(err);
              this.$notify({
                title: "Error",
                text: err.response.data
                  ? err.response.data.Message
                  : "Error while creating site",
                style: "danger",
              });
            });
    },
    createConnection() {
      this.edit
        ? this.connectionsForm
            .put(`${this.$baseUrl}/Setups/FTPConnections/${this.client.id}`)
            .then((res) => {
              this.$store.state.dialog = false;
              this.$notify({
                title: "Success",
                text: `Connection updated successifully.`,
                style: "success",
              });
              this.getConnections();
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
              this.$notify({
                title: "Error",
                text: err.response.data
                  ? err.response.data.Message
                  : `Error while updating connection`,
                style: "danger",
              });
            })
            .finally(() => {
              this.connectionsForm.reset();
            })
        : this.connectionsForm
            .post(`${this.$baseUrl}/Setups/FTPConnections/${this.client.id}`)
            .then((res) => {
              this.$store.state.dialog = false;
              this.$notify({
                title: "Success",
                text: `Connection created successifully.`,
                style: "success",
              });
              this.getConnections();
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
              this.$notify({
                title: "Error",
                text: err.response.data
                  ? err.response.data.Message
                  : `Error while creating connection`,
                style: "danger",
              });
            })
            .finally(() => {
              this.connectionsForm.reset();
            });
    },

    editMode(item) {
      Object.assign(this.connectionsForm, { ...item });
    },

    crudAction() {
      this.edit = false;
      this.connections.map((item) => {
        this.connectionsForm[item.subject] = item.value;
      });
      this.ftp_dialog = true;
    },
  },
  async mounted() {
    try {
      await this.getSites(`/Sites/ClientSites/${this.$route.params.id}`);
      await this.getConnections();
      await this.getGetAdminSystems();
    } catch (error) {
      console.log("error", error);
    }
    await this.getClients();
    await this.getClient(this.$route.params.id);
  },
};
</script>

